import React, { useEffect, useRef, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
// import HomeCover1 from '../../assets/Home/Cover 01.jpg';
// import HomeCover2 from '../../assets/Home/Cover 02.jpg';
// import HomeCover3 from '../../assets/Home/Cover 03.jpg';
// import HomeCover4 from '../../assets/Home/Cover 04.jpg';
// import HomeCover5 from '../../assets/Home/Cover 05.jpg';
// import robotics from '../../assets/Home/robo.png';
// import RocketThumbnail from '../../assets/Home/Rocket Thumbnail.png'
// import CardIcon1 from '../../assets/Home/Icons/HP SR - ICON 1.png'
// import CardIcon2 from '../../assets/Home/Icons/HP SR - ICON 2.png'
// import CardIcon3 from '../../assets/Home/Icons/HP SR - ICON 3.png'
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp';
import Video from '../../Components/Video/Video';
import Video1 from '../../Components/Video/Video1';
import MetaDescription from '../../Components/Meta Description/MetaDescription';

export default function Home() {

    // Cover

    const images = [
        "https://ik.imagekit.io/mhcockpit1/MHB/Home/Cover%2001.jpg",
        "https://ik.imagekit.io/mhcockpit1/MHB/Home/Cover%2002.jpg",
        "https://ik.imagekit.io/mhcockpit1/MHB/Home/Cover%2003.jpg",
        "https://ik.imagekit.io/mhcockpit1/MHB/Home/Cover%2004.jpg",
        "https://ik.imagekit.io/mhcockpit1/MHB/Home/Cover%2005.jpg"
    ]


    // State for current image and text
    const [currentIndex, setCurrentIndex] = useState(0);

    const imageControls = useAnimation();
    const textControls = useAnimation();

    const texts = [
        "Transform Ideas into Reality",
        "Cutting-Edge Education",
        "Build Cool Robots and Have Fun!",
        "DIY - Learning Adventures",
        "Immersive Flight Experiences",
        // "Empower Your Future with Us",
        // "Innovative Learning",
        // "Unmatched Opportunities",
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            imageControls.start({ opacity: 0.5 }).then(() => {
                setCurrentIndex((prev) => (prev + 1) % images.length);
                imageControls.start({ opacity: 1 });
            });
            textControls.start({ y: 50, opacity: 0 }).then(() => {
                textControls.start({ y: 0, opacity: 1 });
            });
        }, 2500); // Change image and text every 5 seconds

        return () => clearInterval(interval);
    }, [imageControls, textControls]);

    // Counter

    // const targetValues = [250, 250, 250, 250];
    // const [values, setValues] = useState([0, 0, 0, 0]);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setValues(prevValues =>
    //             prevValues.map((value, index) =>
    //                 value < targetValues[index] ? value + 1 : targetValues[index] + "+"
    //             )
    //         );
    //     }, 50);
    //     return () => clearInterval(interval);
    // });

    // const descriptions = [
    //     'Hours of Learning',
    //     'Classroom Engaged',
    //     'Practical Learning',
    //     'Students Inspired'
    // ];

    const targetValues = [1000, 75, 160, 300];
    const [values, setValues] = useState([0, 0, 0, 0]);
    const containerRef = useRef(null);
    const intervalRef = useRef(null);

    useEffect(() => {
        const startCounting = () => {
            intervalRef.current = setInterval(() => {
                setValues(prevValues =>
                    prevValues.map((value, index) =>
                        value < targetValues[index] ? value + 1 : targetValues[index] + "+"
                    )
                );
            }, 50);
        };

        const stopCounting = () => {
            clearInterval(intervalRef.current);
            setValues([800, 10, 37, 127]); // Reset values when out of view
        };

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    startCounting();
                } else {
                    stopCounting();
                }
            },
            { threshold: 0.5 } // Trigger when 50% of the component is visible
        );

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            clearInterval(intervalRef.current);
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, []);

    const descriptions = [
        'Hours of Learning*',
        'Classroom Engaged*',
        'Practical Learning*',
        'Students Inspired*'
    ];

    // Video Ref

    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;

        if (video) {
            const playPromise = video.play();

            if (playPromise !== undefined) {
                playPromise
                    .then(_ => {
                        console.log('Autoplay started');
                    })
                    .catch(error => {
                        video.muted = true;
                        video.play().catch(error => {
                            console.error('Autoplay was blocked:', error);
                        });
                    });
            }
        }
    }, []);

    // Kabeer Video

    const [playingVideo, setPlayingVideo] = useState(null);

    const handlePlay = (videoSrc) => {
        setPlayingVideo(videoSrc);
    };

    // Meta Description

    const currentPageLink = window.location.pathname;

    return (
        <div className='bg-white dark:bg-black'>

            {/* Meta Description */}

            <MetaDescription pageLink={currentPageLink} />

            {/* Cover */}

            <div className="relative w-full h-full overflow-hidden">
                <motion.img
                    src={images[currentIndex]}
                    alt="Cover"
                    className="object-cover w-full h-full"
                    initial={{ opacity: 1 }}
                    animate={imageControls}
                    transition={{ duration: 1 }}
                />
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="text-center text-white">
                        {/* <h1 className="text-xl md:text-4xl font-bold mb-4">MHB</h1> */}
                        <motion.p
                            className="text-lg md:text-6xl font-bold"
                            initial={{ y: 50, opacity: 0 }}
                            animate={textControls}
                            transition={{ duration: 1 }}
                        >
                            {texts[currentIndex % texts.length]}
                        </motion.p>
                        {/* <p className="text-lg md:text-2xl mt-2 font-bold">go learn</p> */}
                    </div>
                </div>
            </div>

            {/* Counter */}

            <div>
                {/* <div className="flex items-center justify-center pt-20">
                    <div className="flex flex-wrap gap-10 justify-center items-center">
                        {values.map((value, index) => (
                            <div key={index} className="relative flex flex-col items-center justify-center w-32 h-32 sm:w-24 sm:h-24 md:w-28 md:h-28 lg:w-40 lg:h-40 xl:w-48 xl:h-48">
                                <svg viewBox="0 0 160 160" className="w-full h-full transform -rotate-90">
                                    <defs>
                                        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                                            <stop offset="0%" style={{ stopColor: 'purple', stopOpacity: 1 }} />
                                            <stop offset="100%" style={{ stopColor: 'red', stopOpacity: 1 }} />
                                        </linearGradient>
                                    </defs>
                                    <circle className="fill-none stroke-current text-transparent" cx="80" cy="80" r="70" strokeWidth="6"></circle>
                                    <circle
                                        className="fill-none stroke-current"
                                        style={{ stroke: "url(#gradient)" }}
                                        cx="80"
                                        cy="80" r="70" strokeWidth="8"
                                        strokeDasharray={2 * Math.PI * 70}
                                        strokeDashoffset={2 * Math.PI * 70 - ((value > 100 ? parseInt(value) : value) / targetValues[index]) * 2 * Math.PI * 70}
                                    ></circle>
                                </svg>
                                <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-2xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl font-bold text-white">{value}</div>
                                <div className="mt-5 text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl text-center text-white">{descriptions[index]}</div>
                            </div>
                        ))}
                    </div>
                </div> */}
                <div ref={containerRef} className="flex items-center justify-center pt-20">
                    <div className="flex flex-wrap gap-10 justify-center items-center">
                        {values.map((value, index) => (
                            <div key={index} className="relative flex flex-col items-center justify-center w-32 h-32 sm:w-36 sm:h-36 md:w-40 md:h-40 lg:w-48 lg:h-48 xl:w-52 xl:h-52">
                                <svg viewBox="0 0 160 160" className="w-full h-full transform -rotate-90">
                                    <defs>
                                        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                                            <stop offset="0%" style={{ stopColor: 'purple', stopOpacity: 1 }} />
                                            <stop offset="100%" style={{ stopColor: 'red', stopOpacity: 1 }} />
                                        </linearGradient>
                                    </defs>
                                    <circle className="fill-none stroke-current text-transparent" cx="80" cy="80" r="70" strokeWidth="6"></circle>
                                    <circle
                                        className="fill-none stroke-current"
                                        style={{ stroke: "url(#gradient)" }}
                                        cx="80"
                                        cy="80" r="70" strokeWidth="8"
                                        strokeDasharray={2 * Math.PI * 70}
                                        strokeDashoffset={2 * Math.PI * 70 - ((value > 100 ? parseInt(value) : value) / targetValues[index]) * 2 * Math.PI * 70}
                                    ></circle>
                                </svg>
                                <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-2xl sm:text-xl md:text-2xl lg:text-3xl xl:text-2xl font-bold text-black dark:text-white">{value}</div>
                                <div className="mt-5 text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl text-center text-black dark:text-white">{descriptions[index]}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='px-36 max-sm:px-10 pt-20 max-sm:pt-0'>
                    <FadeInSectionDownToUp>
                        <p className='text-8xl xl:text-8xl lg:text-6xl max-sm:text-2xl py-20 text-center leading-[80px] from-purple-600 via-pink-600 to-blue-600 bg-gradient-to-b bg-clip-text text-transparent'>
                            "Tech-Savvy kids are<br />the architects of the future."
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='pb-36 max-sm:pb-10'>
                    <FadeInSectionDownToUp>
                        <p className='text-black dark:text-white text-xl xl:text-xl lg:text-3xl max-sm:text-sm px-64 xl:px-64 lg:px-32 max-sm:px-10 text-justify'>
                            {/* MH Intellect aims to make 21st-century learning in areas such as coding,
                            Artificial Intelligence, Machine Learning, robotics, Drones, Space science &
                            Aviation both fun and accessible for kids. Our mission is to inspire the next
                            generation of innovators and problem solvers by providing engaging and
                            interactive educational experiences that equip them with the skills needed
                            for the future. */}
                            At MH Intellect, we make 21st-century learning an exciting adventure. Our programs in aviation and robotics offer school students hands-on experiences with satellite technology, flight simulation, drones, CAD design, and robotics. Explore projects with Jetson Nano, Raspberry Pi, ROS, MATLAB, Python with OpenCV, AI, ML, and DIY electronics, including prototype design and robot building. We aim to make these fields engaging and accessible, developing creativity and technical skills for future challenges. Join us to turn learning into an inspiring journey and prepare students for leadership in technology and innovation!
                        </p>
                    </FadeInSectionDownToUp>
                </div>

                <div className='px-80 xl:px-80 lg:px-40 sm:px-40 max-sm:px-5 py-10'>
                    <FadeInSectionDownToUp>
                        <Video1
                            videoSrc="https://mh-intellect.blr1.cdn.digitaloceanspaces.com/intellect%20final.mp4"
                            thumbnailSrc="/Screenshot-thumb.png"
                            className="rounded-3xl"
                            isPlaying={playingVideo === "https://mh-intellect.blr1.cdn.digitaloceanspaces.com/intellect%20final.mp4"}
                            onPlay={handlePlay}
                        />
                    </FadeInSectionDownToUp>
                </div>

                <div className='flex justify-center items-center pt-8 pb-96'>
                    <FadeInSectionDownToUp>
                        <p className='flex-col justify-start items-center'>
                            <span className='text-8xl max-sm:text-5xl text-center leading-[80px] from-purple-600 via-pink-600 to-blue-600 bg-gradient-to-b bg-clip-text text-transparent'>MH Intellect</span><br />
                            <span className='text-black dark:text-white text-center flex-col justify-center items-start'>
                                <span className='text-4xl max-sm:text-xl'>Learning technology today, leading</span><br />
                                <span className='flex gap-2'>
                                    <div>
                                        <span className='text-4xl max-sm:text-xl'>the world</span>
                                    </div>
                                    <div>
                                        <span className='text-8xl max-sm:text-5xl text-center leading-[80px] from-purple-600 via-pink-600 to-blue-600 bg-gradient-to-b bg-clip-text text-transparent'>tomorrow.</span>
                                    </div>
                                </span>
                            </span>
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>

            {/* Card 1 */}

            <div>
                <div className="flex justify-center items-center w-full flex-wrap -mt-52">
                    {/* Card 1 */}
                    <div className="bg-white rounded-[20px] overflow-hidden shadow-lg m-2 w-full md:w-1/3 xl:w-1/3 h-[40rem] relative flex flex-col">
                        <video autoPlay muted loop className="w-full h-full object-cover">
                            <source src="https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Card%2001%20-%20STEM.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <div className="absolute bottom-[49px] left-4 text-black z-10   p-4 rounded-md">
                            <FadeInSectionDownToUp>
                                <p className='text-start px-5 w-96'>
                                    {/* <b>STEM</b> Education enhances skills in Science, Technology, Engineeringand Mathematics. */}
                                    <b>STEM - LN2 Demos and Fun Learning Experiences!</b>
                                </p>
                            </FadeInSectionDownToUp>
                        </div>
                    </div>
                    {/* Card 2 */}
                    <div className="bg-white rounded-[20px] overflow-hidden shadow-lg m-2 w-full md:w-1/3 xl:w-1/3 h-[40rem] flex flex-col justify-center items-center">
                        <div className=" inset-0 flex-col items-center justify-center text-black z-10 text-center p-4 bg-white bg-opacity-75 rounded-md">
                            <div className='flex justify-center items-center'>
                                <img className='w-32 pb-5' src="https://ik.imagekit.io/mhcockpit1/MHB/Home/Icons/HP%20SR%20-%20ICON%201.png" alt='icon' />
                            </div>
                            <FadeInSectionDownToUp>
                                <p className='px-5 text-justify'>
                                    {/* STEM education integrates Science, Technology, Engineering, and Mathematics to prepare students for
                                    a technology-driven world. It emphasizes hands-on learning and interdisciplinary approaches to make
                                    connections between these fields. By fostering critical thinking and problem-solving, STEM helps
                                    students innovate and adapt to new challenges.The focus on Practical Experience and Collaboration
                                    enhances career readiness. Additionally, STEM education strives to be inclusive, addressing disparities
                                    and encouraging participation from diverse backgrounds. This approach not only equips students with
                                    essential skills but also promotes lifelong learning and discovery. */}
                                    The new world of Science, Technology, Engineering, and Mathematics with our amazing demonstrations! Watch as we launch rockets with a dramatic explosion, create enchanting fog instantly, and see flowers shatter in a stunning display! Be captivated by the spectacular reaction when liquid nitrogen (vs) fire. These incredible shows turn learning into an adventure, sparking curiosity and making science both fun and fascinating. Get ready to witness exciting discoveries and enjoy the wonder of scientific exploration!
                                </p>
                            </FadeInSectionDownToUp>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='px-80 xl:px-80 lg:px-40 sm:px-40 max-sm:px-5 py-10'>
                <FadeInSectionDownToUp>
                    <Video1
                        videoSrc="/intellect final.mp4"
                        thumbnailSrc="/Screenshot-thumb.png"
                        className="rounded-3xl"
                    />
                </FadeInSectionDownToUp>
            </div> */}

            {/* Robotic */}

            <div>
                <FadeInSectionDownToUp>
                    <div className='px-80 xl:px-80 lg:px-40 sm:px-40 max-sm:px-5'>
                        <div className="flex flex-col md:flex-row w-full h-full bg-white rounded-3xl shadow-lg overflow-hidden custom-container mx-auto my-8">
                            <div className="w-full md:w-4/5 h-full">
                                <img src="https://ik.imagekit.io/mhcockpit1/MHB/Home/robo.png" alt="Robotics" className="custom-image" />
                            </div>
                            <div className="w-full md:w-1/2 p-6 md:p-12 lg:p-16 flex flex-col justify-center">
                                <FadeInSectionDownToUp>
                                    <h2 className="text-4xl font-bold mb-4">Robotics <br /> pet.</h2>
                                    <p className="text-lg mb-4 text-justify">
                                        {/* Explore the fascinating world of robotics. Learn about the latest advancements in technology, AI, and automation. */}
                                        Step into the innovative world of robotics with a pet that combines technology and fun!
                                    </p>
                                </FadeInSectionDownToUp>
                            </div>
                        </div>
                    </div>
                </FadeInSectionDownToUp>
            </div>

            {/* Card 2 */}

            <div>
                <FadeInSectionDownToUp>
                    <div className="flex justify-center items-center w-full p-5 flex-wrap">
                        {/* Card 2 */}
                        <div className="bg-white rounded-[20px] overflow-hidden shadow-lg m-2 w-full md:w-1/3 h-[40rem] flex flex-col justify-center items-center">
                            <div className="inset-0 flex-col items-center justify-center text-black z-10 text-center p-4 bg-white bg-opacity-75 rounded-md">
                                <div className='flex justify-center items-center'>
                                    <img className='w-32 pb-5' src="https://ik.imagekit.io/mhcockpit1/MHB/Home/Icons/HP%20SR%20-%20ICON%202.png" alt='icon' />
                                </div>
                                <FadeInSectionDownToUp>
                                    <p className='px-5 text-justify'>
                                        {/* Aviation Drone Technology involves unmanned aerial vehicles (UAVs) used for a range of applications,
                                        from recreational to commercial and military uses. These drones are equipped with advanced sensors,
                                        cameras, and GPS systems for tasks such as aerial photography, surveying, and surveillance. They offer
                                        benefits like cost efficiency and the ability to access hard-to-reach areas. Innovations include improved
                                        autonomous flight capabilities, battery life, and enhanced data collection. Regulatory frameworks are
                                        evolving to ensure safe and responsible use. Drones are also increasingly used in industries like
                                        agriculture, logistics, and disaster response. As technology advances, their capabilities and applications
                                        continue to expand. */}
                                        Discover our selection of cutting-edge UAVs and RC aircraft. From nano drones ideal for indoor flights and beginner pilots, to quadcopters and hexacopters that offer stable, versatile flying for photography and professional tasks. Our foam board RC aircraft are lightweight and customizable, while 3D printed RC models provide unique, precision designs. For high-speed performance, check out our EDF RC aircraft. Find the perfect model to elevate your flying experience!
                                    </p>
                                </FadeInSectionDownToUp>
                            </div>
                        </div>
                        {/* Card 1 */}
                        <div className="bg-white rounded-[20px] overflow-hidden shadow-lg m-2 w-full md:w-1/3 h-[40rem] relative flex flex-col">
                            <video autoPlay muted loop className="w-full h-full object-cover">
                                <source src="https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Card%2003%20-%20Aeronautics.mp4" type="video/mp4" />
                            </video>
                            <div className="absolute bottom-8 left-7 max-sm:left-2 text-white z-20 p-4 xl:w-72 text-justify rounded-md">
                                <FadeInSectionDownToUp>
                                    <p>Our young pilots get to master drone operation through our aviation and aerospace curriculum, taking control of small aircraft and gaining hands-on experience in the field.</p>
                                </FadeInSectionDownToUp>
                            </div>
                        </div>
                    </div>
                </FadeInSectionDownToUp>
            </div>

            {/* Video 1 */}

            <div>
                <div>
                    <div className='xl:px-80 lg:px-40 max-md:px-40 md:px-20 max-sm:py-5 max-sm:px-5 sm:px-5 relative'>
                        {/* <video src={video1} ref={videoRef} autoPlay playsInline loop className='w-full rounded-3xl video_border' /> */}
                        <video autoPlay muted loop className="w-full rounded-3xl video_border">
                            <source src="https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Card%2004%20-%20Flight%20Simulators.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <div className='absolute bottom-20 xl:left-96 lg:left-48 max-sm:bottom-5 md:left-24 sm:left-10 sm:bottom-16 max-md:left-48 max-sm:left-2 px-4 max-sm:px-10 py-4'>
                            <FadeInSectionDownToUp>
                                <h1 className='text-white xl:text-lg sm:text-lg max-sm:text-lg md:w-96 text-start max-sm:text-justify font-normal tracking-tight'>
                                    <b>Flight Simulator training aids school kids’ understanding of Aviation,</b> spatial skills, and interest in Aviation careers.
                                </h1>
                            </FadeInSectionDownToUp>
                        </div>
                    </div>
                </div>
            </div>

            {/* Card 3 */}

            <div>
                <FadeInSectionDownToUp>
                    <div>
                        <div className="flex justify-center items-center w-full p-5 flex-wrap">
                            {/* Card 1 */}
                            <div className="rounded-[20px] overflow-hidden shadow-lg m-2 w-full md:w-1/3 h-[40rem] relative flex flex-col">
                                <Video
                                    videoSrc="https://mh-intellect.blr1.cdn.digitaloceanspaces.com/UAE_INTELLECT%20EXP%209%20Bottle%20Rocket.mp4"
                                    thumbnailSrc="https://ik.imagekit.io/mhcockpit1/MHB/Home/Rocket%20Thumbnail.png"
                                />
                                <div className="absolute bottom-[49px] xl:w-80 right-[40px] text-end max-sm:text-justify text-white z-10 p-4 rounded-md">
                                    <p>
                                        Aerospace studies prepare kids for Future careers and cover flight, design, and propulsion with hands-on activities.
                                    </p>
                                </div>
                                <div className="absolute inset-0 pointer-events-none before:content-[''] before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-b before:from-transparent before:to-black"></div>
                            </div>
                            {/* Card 2 */}
                            <div className="bg-white rounded-[20px] overflow-hidden shadow-lg m-2 w-full md:w-1/3 h-[40rem] flex items-center">
                                <div className="inset-0 flex-col items-center justify-center text-black z-10 text-center p-4 bg-white bg-opacity-75 rounded-md">
                                    <div className='flex justify-center items-center'>
                                        <img className='w-32 pb-5' src="https://ik.imagekit.io/mhcockpit1/MHB/Home/Icons/HP%20SR%20-%20ICON%203.png" alt='icon' />
                                    </div>
                                    <FadeInSectionDownToUp>
                                        <p className='px-5 text-justify'>
                                            Aerospace studies for school kids introduce flight principles, aircraft design, and space exploration through engaging hands-on activities. These studies enhance problem-solving skills and deepen understanding of technology. They also spark interest in aviation and inspire future STEM careers. By combining practical experience with foundational knowledge, students gain valuable insights into the aerospace field.
                                        </p>
                                    </FadeInSectionDownToUp>
                                </div>
                            </div>
                        </div>
                    </div>
                </FadeInSectionDownToUp>
            </div>

            {/* Video 2 */}

            <div className='px-80 xl:px-80 lg:px-40 sm:px-40 max-sm:px-5 py-10'>
                <FadeInSectionDownToUp>
                    {/* <Video1
                        videoSrc="/Kabeer Video.mp4"
                        thumbnailSrc="/Kabeer Thumbnail.png"
                        className="rounded-3xl"
                    /> */}
                    <Video1
                        videoSrc="https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Kabeer%20Video.mp4"
                        thumbnailSrc="/Kabeer Thumbnail.png"
                        className="rounded-3xl"
                        isPlaying={playingVideo === "https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Kabeer%20Video.mp4"}
                        onPlay={handlePlay}
                    />
                </FadeInSectionDownToUp>
            </div>

            <div>
                <div className='pb-56'>
                    <div className='xl:px-80 lg:px-40 max-md:px-40 md:px-20 max-sm:py-5 max-sm:px-5 sm:px-5 relative'>
                        {/* <video src={video2} ref={videoRef} autoPlay playsInline loop className='w-full rounded-3xl video_border' /> */}
                        <video autoPlay muted loop className="w-full rounded-3xl video_border">
                            <source src="https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Card%2006%20-%20AI%20&%20ML.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <div className='absolute bottom-20 xl:right-96 lg:right-48 max-lg:right-0 max-sm:bottom-5 md:right-24 max-md:right-48 sm:right-20 max-sm:left-4 max-sm:right-5 sm:bottom-16 px-4 py-4'>
                            <FadeInSectionDownToUp>
                                <h1 className='text-white xl:text-lg sm:text-lg max-sm:text-lg md:w-96 text-start max-sm:text-justify font-normal tracking-tight'>
                                    {/* From AI and ML to building model flights to researching on cansat ,we are on a mission to immerse young minds in the futuristic world in such an engaging way that they forget they’re actually learning and preparing. MHB : Where Curiosity Takes Flight and Innovation Knows No Bounds. */}
                                    {/* The world of AI and ML at MH Intellect! Discover how to make smart robots and amazing apps, all while having fun and letting your imagination run wild! */}
                                    Learn cutting-edge technology and have fun turning your imaginative ideas into reality.
                                </h1>
                            </FadeInSectionDownToUp>
                        </div>
                    </div>
                </div>
            </div>

            {/* India Ops */}

            <div>
                <p className='text-slate-300 dark:text-slate-700'>
                    Details marked with an asterisk (*) specifically refer to operations in India.
                </p>
            </div>

        </div>
    )
}
