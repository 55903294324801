const products = [
  // RC Planes Project
  {
    id: 11,
    productImage: "/Product Details Page/Intellect Advanced STEM Kit Box.jpg",
    title: "Advanced STEM Kit",
    description: "",
    buttonText: "Launch Your Learning Journey",
    // details: "MH Intellect Advanced STEM Kit! Perfect for young inventors, this kit includes a Robotic Arm, Hexapod, and WiFi Car, giving you the chance to build, program, and control your own robots.",
    details: "The Advanced Robotics STEM Kit is a comprehensive learning tool designed to introduce school students to the exciting world of robotics. This kit provides everything needed to build, program, and experiment with robots through hands-on projects like light-following robots, pick-and-place machines, and smartphone-controlled devices. Students will engage with essential STEM fields, including electronics, mechanical design, and coding, while developing critical thinking and problem-solving skills. Suitable for both classroom settings and home learning, the kit is designed to foster creativity and innovation.",
    outcomeTitle: "Outcome",
    // outcomeDescription: "Learn about robotics, coding, and electronics, giving you a head start in STEM subjects.",
    outcomeDescription: "By using the Advanced Robotics STEM Kit, students will gain a deeper understanding of robotics and its applications. They will develop key skills in programming, mechanics, and electronics, while building confidence in their ability to solve complex problems. The kit encourages students to think critically, work collaboratively, and apply theoretical knowledge to real-world challenges. Ultimately, students will walk away with a solid foundation in robotics and a passion for STEM learning.",
    // outcomeDescription: [
    //   {
    //     point: "Hands-on projects for real-world learning."
    //   },
    //   {
    //     point: "Perfect for classrooms and home experiments."
    //   },
    //   {
    //     point: "Designed to develop problem-solving skills."
    //   },
    // ],
    features: [
      {
        title: "Quality",
        description: "All materials are designed with safety in mind, making it a perfect kit for young learners.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Innovation",
        description: "Provides opportunities to explore artificial intelligence concepts, enabling robots to learn and adapt to new tasks.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    // conclusionText: "Join a community of young inventors who love building and sharing their creations.",
    conclusionText: "Imagine building your own robots, circuits, and gadgets—all in one kit! The Advanced STEM Kit is packed with cool, hands-on projects that help you explore science, technology, engineering, and math in a fun and interactive way. Perfect for school projects or just because you're curious!",
  },
  {
    id: 6,
    productImage: "/Product Details Page/Quadcopter.jpg",
    title: "Quadcopter",
    description: "Advanced Level",
    buttonText: "Expand Your Horizons",
    // details: "Designed for advanced pilots, our quadcopters provide superior stability and advanced control. With enhanced features and capabilities, they are ideal for practicing complex maneuvers and capturing high-quality aerial footage.",
    details: "The Quadcopter STEM Kit is designed to give school students a hands-on introduction to drone technology. This kit includes all the components needed to assemble, program, and fly a quadcopter, allowing students to explore the principles of aerodynamics, electronics, and control systems. Through this engaging experience, students will learn how drones work and gain practical skills in assembly, programming, and piloting.",
    outcomeTitle: "Outcome",
    // outcomeDescription: "You'll enhance your control and precision, preparing for advanced flight challenges with a sophisticated drone.",
    outcomeDescription: "By completing the Quadcopter STEM Kit projects, students will gain a strong understanding of drone technology and its applications. They will develop skills in programming, engineering, and aerodynamics while enhancing problem-solving and teamwork abilities. This kit empowers students to think innovatively and prepares them for future exploration in robotics, aviation, and technology.",
    features: [
      {
        title: "Quality",
        description: "Superior materials and innovative designs ensure top performance and durability.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Expert advice and assistance are always available to help you succeed.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Community",
        description: "Join our passionate drone enthusiasts and share your experiences.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    // conclusionText: "MH Intellect drones offer an unmatched flying experience for every skill level. Explore our range and elevate your aerial adventure today!",
    conclusionText: "Ready to take to the skies? With our Quadcopter, you can pilot your own drone and learn about flight in a super fun way! It’s easy to fly and gives you the perfect start to aerial adventures, whether you're snapping photos from above or racing with friends.",
  },
  {
    id: 9,
    productImage: "/Product Details Page/Can Sat.jpg",
    title: "CanSat",
    description: "Educational Satellite Model",
    buttonText: "Launch Your Learning Journey",
    // details: "The CanSat is a smaller, educational satellite model that simulates the functionality of real satellites. It's perfect for educational purposes, providing students and hobbyists with a practical introduction to satellite technology and space missions.",
    details: "The CanSat Kit provides students with a hands-on way to explore satellite technology and space systems. With this kit, students can build and launch their own mini satellite, learning how sensors work, how to collect data, and how telemetry functions in real-world applications. It's an exciting opportunity to learn about aerospace engineering, communication systems, and environmental monitoring in a practical and engaging manner.",
    outcomeTitle: "Outcome",
    // outcomeDescription: "You'll build a foundational understanding of satellite operations, data collection, and space mission planning, all while engaging in a hands-on learning experience.",
    outcomeDescription: "By completing the CanSat project, students will gain a strong understanding of how satellites operate and their use in everyday life. They will develop skills in coding, data analysis, and design while learning how to measure and interpret environmental data. This experience promotes problem-solving, teamwork, and technical thinking, setting the stage for future studies in engineering and technology.",
    features: [
      {
        title: "Quality",
        description: "We use advanced materials and technologies to ensure the reliability and performance of our CubeSats and CanSats.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Innovation",
        description: "Stay at the forefront of space technology with our cutting-edge satellite platforms and educational tools.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    // conclusionText: "Explore MH Intellect's CubeSat and CanSat solutions and take your space exploration journey to new heights today!",
    conclusionText: "Have you ever wondered what it’s like to launch your own satellite? With the CanSat kit, you can design and launch a mini satellite just like the real thing. Learn how space technology works and send your creation soaring into the sky!",
  },
  {
    id: 1,
    productImage: "/Product Details Page/Foam Board RC.jpg",
    title: "Foam Board RC Plane",
    description: "Beginner Level",
    buttonText: "Start Your Journey",
    // details: "Learn the basics of RC flying with easy-to-assemble, durable, and stable foam board planes. The lightweight construction is forgiving and ideal for new pilots.",
    details: "The Foam Board RC Plane Kit allows students to experience the fundamentals of flight by building their own remote-controlled airplane. This kit provides all the necessary materials to assemble a lightweight foam board plane, teaching students the principles of aerodynamics, electronics, and remote control systems. It’s an excellent way to explore the basics of aviation while developing hands-on skills in building and piloting.",
    outcomeTitle: "Outcome",
    // outcomeDescription: "Gain essential flying skills and confidence in operating RC planes, laying the groundwork for advanced remote-controlled aviation pursuits.",
    outcomeDescription: "Through the Foam Board RC Plane project, students will learn how airplanes fly and the mechanics behind flight control. They will enhance their understanding of aerodynamics, motor function, and electronics, while building valuable skills in problem-solving and creative thinking. By the end of the project, students will have a functional RC plane and a deeper appreciation for aviation and engineering concepts.",
    features: [
      {
        title: "Quality",
        description: "Superior materials and innovative designs ensure top performance and durability.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Expert advice and assistance are always available to help you succeed.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Community",
        description: "Join our passionate RC enthusiasts and share your experiences.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    // conclusionText: "MH Intellect RC planes offer an unmatched flying experience for every skill level. Explore our range and elevate your RC flying adventure today!",
    conclusionText: "Building your own plane has never been easier! The Foam Board RC Plane is perfect for students who want to learn about aerodynamics while having fun. It’s simple to construct and thrilling to fly, making it a great project for anyone who loves aviation.",
  },
  {
    id: 12,
    productImage: "/Product Details Page/WiFi Car.jpg",
    title: "Rover Car",
    description: "",
    buttonText: "Launch Your Learning Journey",
    // details: "Exciting adventure with the Rover Car, part of the MH Intellect Advanced STEM Kit! Perfect for young inventors, this kit lets you build, program, and control your own WiFi-enabled rover car, sparking creativity and innovation.",
    details: "The Rover Car Kit offers an engaging way for learners to build and program their own mobile rover. With components that include sensors and motors, participants will assemble and program the car to navigate and perform tasks, all while exploring key concepts in robotics, automation, and mobility. It’s a hands-on experience that bridges the gap between theory and real-world application.",
    outcomeTitle: "Outcome",
    // outcomeDescription: "Equip yourself with skills that prepare you for exciting opportunities in technology and engineering.",
    outcomeDescription: "Through the Rover Car project, participants will gain practical experience in robotics, learning how to integrate sensors for obstacle detection and design efficient navigation systems. This project enhances critical thinking and problem-solving skills while fostering creativity and a deep understanding of robotics and mechanical systems.",
    features: [
      {
        title: "Quality",
        description: "All materials are designed with safety in mind, ensuring a safe and enjoyable learning experience.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Innovation",
        description: "Experiment with different designs and functions to create a rover that's uniquely yours.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    // conclusionText: "Share your projects and get inspired by others in the community.",
    conclusionText: "Get ready to design your very own rover! This Rover Car project teaches you about robotics, coding, and mechanics as you build and program a car that can navigate all kinds of terrain. Perfect for young engineers looking to explore the world of autonomous vehicles.",
  },
  {
    id: 2,
    productImage: "/Product Details Page/RC plane.jpg",
    title: "3D Printed RC Plane",
    description: "Intermediate Level",
    buttonText: "Elevate Your Skills",
    // details: "For intermediate pilots, this course features a 3D printed frame for more structural integrity, with realistic designs and responsive controls for skill development.",
    details: "The 3D Printed RC Plane Kit is a fun and educational tool designed specifically for school students. It provides everything needed to design, 3D print, and assemble a remote-controlled airplane. Through this hands-on project, students will not only learn about aerodynamics and flight mechanics but also how to work with 3D printing technology. It's the perfect blend of creativity and engineering for young innovators eager to build something that can actually take to the skies.",
    outcomeTitle: "Outcome",
    // outcomeDescription: "You'll improve control and maneuvering skills, gaining experience with more powerful and responsive RC planes.",
    outcomeDescription: "By completing the 3D Printed RC Plane project, students will develop key skills in 3D design, printing, and assembly. They will also gain a practical understanding of how planes fly and how remote control systems work. This project encourages problem-solving, innovation, and technical skills, while giving students the unique opportunity to watch their creations.",
    features: [
      {
        title: "Quality",
        description: "Superior materials and innovative designs ensure top performance and durability.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Expert advice and assistance are always available to help you succeed.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Community",
        description: "Join our passionate RC enthusiasts and share your experiences.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    // conclusionText: "MH Intellect RC planes offer an unmatched flying experience for every skill level. Explore our range and elevate your RC flying adventure today!",
    conclusionText: "Ever thought of designing and flying something you've built from scratch? The 3D Printed RC Plane lets you bring your own custom plane to life, straight from the 3D printer! It’s the perfect blend of creativity, technology, and aviation.",
  },
  {
    id: 5,
    productImage: "/Product Details Page/Nano Drone.jpg",
    title: "Nano Drone",
    description: "Beginner Level",
    buttonText: "Start Your Aerial Adventure",
    // details: "Learn the basics of drone flying with our easy-to-control, compact nano drones. Their lightweight and durable design make them ideal for new pilots, offering a forgiving flight experience as you master the fundamentals.",
    details: "The Nano Drone Kit offers an exciting entry point for school students interested in drone technology. Designed for beginners, this kit provides all the components required to build and pilot a small, easy-to-use drone. As students assemble and operate the drone, they’ll explore fundamental concepts such as aerodynamics, stability, and basic electronics in a straightforward and enjoyable manner, perfect for sparking interest in tech and engineering.",
    outcomeTitle: "Outcome",
    // outcomeDescription: "Develop basic flying skills and gain confidence in handling nano drones, setting the stage for more advanced drone piloting.",
    outcomeDescription: "Working with the Nano Drone Kit allows students to get hands-on experience with drone assembly and flight control. They will develop a practical understanding of how drones maneuver and the role of electronics in controlling movement. This project encourages critical thinking and provides an accessible introduction to technology, giving students a solid foundation to explore more advanced topics in robotics and flight.",
    features: [
      {
        title: "Quality",
        description: "Superior materials and innovative designs ensure top performance and durability.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Expert advice and assistance are always available to help you succeed.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Community",
        description: "Join our passionate drone enthusiasts and share your experiences.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    // conclusionText: "MH Intellect drones offer an unmatched flying experience for every skill level. Explore our range and elevate your aerial adventure today!",
    conclusionText: "Tiny but mighty, the Nano Drone is perfect for students who want to learn how to pilot drones indoors or outdoors. Its compact size makes it great for practicing tricks and maneuvers, and it's super fun to fly with friends!",
  },
  {
    id: 3,
    productImage: "/Product Details Page/Avanti S.jpg",
    title: "Fighter EDF RC Plane",
    description: "Advanced Level",
    buttonText: "Master the Skies",
    // details: "Designed for advanced pilots, this course covers high-speed performance and precision, with models built for aerobatics and complex maneuvers.",
    details: "The Fighter EDF RC Plane Kit is perfect for those eager to explore high-speed, jet-like flight. With its electric ducted fan (EDF) system, this kit allows aspiring builders to create a fighter-style plane that mimics real-world jet propulsion. The project introduces key concepts in aerodynamics, thrust, and control, making it an exciting way to learn about advanced aircraft mechanics while assembling a high-performance remote-controlled plane.",
    outcomeTitle: "Outcome",
    // outcomeDescription: "You'll achieve advanced flying techniques, perform complex aerobatic stunts, and enjoy high-speed, precise control.",
    outcomeDescription: "By completing the Fighter EDF RC Plane project, builders will gain practical insights into jet propulsion, flight dynamics, and precision control. This hands-on experience enhances technical problem-solving skills and provides a deeper understanding of aircraft maneuverability. The end result is not just an educational journey but also the thrill of piloting a custom-built, fast-flying RC plane.",
    features: [
      {
        title: "Quality",
        description: "Superior materials and innovative designs ensure top performance and durability.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Expert advice and assistance are always available to help you succeed.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Community",
        description: "Join our passionate RC enthusiasts and share your experiences.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    // conclusionText: "MH Intellect RC planes offer an unmatched flying experience for every skill level. Explore our range and elevate your RC flying adventure today!",
    conclusionText: "Ready to pilot a jet? The Fighter EDF RC Plane is for students who love speed and precision. You’ll get hands-on with the mechanics of flight and experience the thrill of controlling a high-speed jet right in your backyard.",
  },

  // Gliders Project
  {
    id: 4,
    productImage: "/Product Details Page/Glider.jpg",
    title: "Glider",
    description: "",
    buttonText: "Experience the Art of Gliding",
    // details: "Discover the thrill of silent flight with MH Intellect gliders. Our gliders are designed for stability, efficiency, and performance, providing an exceptional flying experience.",
    details: "The Glider Kit provides an exciting introduction to the basics of flight. With this kit, learners can build a lightweight, aerodynamic glider designed for smooth, unpowered flights. It covers important concepts such as lift, drag, and stability, helping students explore how air affects flight and how simple design choices can lead to longer, more stable glides.",
    outcomeTitle: "Outcome",
    // outcomeDescription: "Achieve a deep understanding of gliding principles, master basic to advanced gliding techniques, and develop the skills to perform efficient and controlled flights. Join a community of passionate gliding enthusiasts.",
    outcomeDescription: "By building and experimenting with the Glider, participants will gain a practical understanding of fundamental aerodynamics and how forces like lift and drag influence movement. This project encourages creative thinking and problem-solving as they refine their designs for optimal performance, all while enjoying the thrill of seeing their glider soar effortlessly through the air.",
    features: [
      {
        title: "Quality",
        description: "Superior materials and innovative designs ensure optimal performance and durability.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Our expert team provides advice and guidance to help you succeed.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Community",
        description: "Join our passionate gliding enthusiasts and share your experiences.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    // conclusionText: "Elevate your gliding experience with MH Intellect. Explore our range of gliders and take flight today!",
    conclusionText: "Ever wanted to launch something into the sky and watch it glide? The Glider is a simple yet exciting project where you can explore the basics of flight and aerodynamics. It’s perfect for experimenting with different designs and seeing how far you can make it soar!",
  },
  {
    id: 7,
    productImage: "/Product Details Page/Hexacopter.jpg",
    title: "Hexacopter",
    description: "Expert Level",
    buttonText: "Command the Skies",
    // details: "For experienced pilots seeking top performance, our hexacopters offer exceptional stability and power with six rotors. Built for high-speed flights and advanced maneuvers, they provide a professional-grade flying experience.",
    details: "The Hexacopter Kit offers an immersive experience for students interested in drone technology. This kit includes everything needed to build a six-rotor drone, providing enhanced stability and precise control compared to traditional quadcopters. As they work through the assembly, students will explore key concepts like multi-rotor flight dynamics, motor synchronization, and the basics of drone programming.",
    outcomeTitle: "Outcome",
    // outcomeDescription: "You'll master advanced flying techniques, perform precise maneuvers, and enjoy superior control in challenging conditions.",
    outcomeDescription: "By completing the Hexacopter project, learners will develop a deep understanding of drone mechanics and control systems. They will enhance their problem-solving abilities by learning how to manage flight stability and efficient maneuvering. This hands-on project offers a thorough introduction to modern drone technology, helping them build both practical and technical skills.",
    features: [
      {
        title: "Quality",
        description: "Superior materials and innovative designs ensure top performance and durability.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Expert advice and assistance are always available to help you succeed.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Community",
        description: "Join our passionate drone enthusiasts and share your experiences.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    // conclusionText: "MH Intellect drones offer an unmatched flying experience for every skill level. Explore our range and elevate your aerial adventure today!",
    conclusionText: "Take your drone skills to the next level with the Hexacopter. With six propellers, it’s more stable and offers a smoother flight experience. Learn about advanced drone technology and how to control more complex aerial machines.",
  },

  // Space Projects
  {
    id: 8,
    productImage: "/Product Details Page/Cube SAT.jpg",
    title: "CubeSat",
    description: "Advanced Satellite Platform",
    buttonText: "Unlock Space Exploration",
    // details: " Our CubeSats provide a modular and versatile platform for a wide range of space missions. Designed to meet rigorous standards, these small satellites offer high-performance capabilities in a compact form factor. Ideal for scientific research, technology demonstration, and Earth observation",
    details: "The CubeSat Kit offers a hands-on experience for school students interested in satellite technology. This kit allows them to design, build, and test a mini-satellite similar to those used in space missions. Through practical activities, students learn about satellite components, data transmission, and sensor integration, gaining insights into how satellites help with communication, research, and exploration.",
    outcomeTitle: "Outcome",
    // outcomeDescription: " You'll gain hands-on experience with space technology, conduct experiments in orbit, and develop skills in satellite design and operations.",
    outcomeDescription: "By completing the CubeSat project, students gain real-world experience in building and programming satellite systems. They’ll learn how to collect and analyze data using sensors, understand the basics of satellite communication, and develop essential problem-solving and technical skills that can be applied to future projects in aerospace and technology.",
    features: [
      {
        title: "Quality",
        description: "We use advanced materials and technologies to ensure the reliability and performance of our CubeSats and CanSats.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Innovation",
        description: "Stay at the forefront of space technology with our cutting-edge satellite platforms and educational tools.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    // conclusionText: "Explore MH Intellect's CubeSat and CanSat solutions and take your space exploration journey to new heights today!",
    conclusionText: "Dreaming of space exploration? CubeSat lets you build a mini satellite that simulates real-life space missions. You’ll dive into aerospace engineering and get a taste of what it’s like to design technology that could one day orbit the Earth!",
  },
  {
    id: 10,
    productImage: "/Product Details Page/Humanoid.jpg",
    title: "Humanoid Robot",
    description: "",
    buttonText: "Launch Your Learning Journey",
    // details: "The Humanoid Kit, where creativity meets technology! This hands-on kit allows kids to build and program a humanoid robot that can walk and dance, introducing them to the exciting world of robotics, coding and AI.",
    details: "The Humanoid Robot Kit allows students to explore robotics by building and programming a robot that mimics human movements. This kit provides everything needed to assemble a walking, talking, and interacting robot. As students work through the project, they’ll learn about motor control, sensors, and coding while experiencing how robots can be designed to perform tasks similar to humans.",
    outcomeTitle: "Outcome",
    // outcomeDescription: "Develop critical thinking and troubleshooting abilities through hands-on robot assembly and coding challenges.",
    outcomeDescription: "Upon completing the Humanoid Robot project, students will have hands-on experience with robotics, learning how to program movements and integrate sensors for interaction. This project develops skills in coding, problem-solving, and mechanical design, offering a practical understanding of how robots can be used in real-world applications like assistance and automation.",
    features: [
      {
        title: "Quality",
        description: "Each kit includes durable and reliable parts designed to withstand repeated use and experimentation.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Innovation",
        description: "Provides opportunities to explore artificial intelligence concepts, enabling robots to learn and adapt to new tasks.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    // conclusionText: "Inspire innovation and creativity with the Humanoid Kit, preparing young minds to become pioneers in technology and robotics.",
    conclusionText: "Imagine building a robot that walks, talks, and interacts with you! The Humanoid Robot project lets students explore robotics, coding, and artificial intelligence as they build a robot that can move and even recognize faces. It’s a fun way to learn about the future of tech!",
  },
  {
    id: 13,
    productImage: "/Product Details Page/DIY Writing Machine.jpg",
    title: "DIY Drawing Machine",
    description: "",
    buttonText: "Launch Your Learning Journey",
    // details: "The Humanoid Kit, where creativity meets technology! This hands-on kit allows kids to build and program a humanoid robot that can walk and dance, introducing them to the exciting world of robotics, coding and AI.",
    details: "The DIY Writing & Art Machine is a super cool way for kids to combine drawing and writing! With this fun machine, you can easily create awesome designs and write down your thoughts, all by turning a couple of knobs. It’s easy to build, made from safe materials, and gives you the chance to explore your creativity while learning something new.",
    outcomeTitle: "Outcome",
    // outcomeDescription: "Develop critical thinking and troubleshooting abilities through hands-on robot assembly and coding challenges.",
    outcomeDescription: "With the DIY Writing & Art Machine, you’ll get to improve your drawing skills and practice writing in a whole new way. As you make cool patterns and write your own messages, you’ll also learn how to solve problems, understand basic math concepts like shapes and lines, and boost your hand-eye coordination. It’s the perfect mix of fun and learning!",
    features: [
      {
        title: "Quality",
        description: "Each kit includes durable and reliable parts designed to withstand repeated use and experimentation.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Innovation",
        description: "Provides opportunities to explore artificial intelligence concepts, enabling robots to learn and adapt to new tasks.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    // conclusionText: "Inspire innovation and creativity with the Humanoid Kit, preparing young minds to become pioneers in technology and robotics.",
    conclusionText: "Let your creativity shine with the DIY Drawing Machine! This project teaches you how to build a machine that draws cool patterns and designs. It’s a fun mix of art and engineering, and you can even program it to create your own unique artwork!",
  },
  {
    id: 14,
    productImage: "/Product Details Page/DIY Smart Farm Kit.jpg",
    title: "DIY Smart Farm Kit",
    description: "",
    buttonText: "Launch Your Learning Journey",
    // details: "The Humanoid Kit, where creativity meets technology! This hands-on kit allows kids to build and program a humanoid robot that can walk and dance, introducing them to the exciting world of robotics, coding and AI.",
    details: "The DIY Smart Farm Kit lets kids explore the world of farming and technology by building their own smart farm! This hands-on kit teaches you how to grow plants using sensors and automation, just like real smart farms do. It’s fun and easy to set up, with safe materials, and helps you learn how technology can make farming more efficient.",
    outcomeTitle: "Outcome",
    // outcomeDescription: "Develop critical thinking and troubleshooting abilities through hands-on robot assembly and coding challenges.",
    outcomeDescription: "With the DIY Smart Farm Kit, you’ll learn how to use sensors to monitor things like soil moisture and light levels to help your plants grow. You’ll also discover the basics of programming and automation, gaining problem-solving skills and understanding how smart technology can improve farming. It’s a great way to mix science, technology, and nature while having fun growing your own plants!",
    features: [
      {
        title: "Quality",
        description: "Each kit includes durable and reliable parts designed to withstand repeated use and experimentation.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Innovation",
        description: "Provides opportunities to explore artificial intelligence concepts, enabling robots to learn and adapt to new tasks.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    // conclusionText: "Inspire innovation and creativity with the Humanoid Kit, preparing young minds to become pioneers in technology and robotics.",
    conclusionText: "Ever wondered how technology can help plants grow? With the DIY Smart Farm Kit, you’ll get to create your own mini farm that uses sensors and smart technology to take care of plants. Learn how to monitor soil moisture, control watering systems, and even track sunlight—just like real farmers do! It’s a hands-on project that combines coding, robotics, and nature, giving you the chance to grow something amazing while learning how smart farms work. Perfect for budding scientists and future environmental engineers!",
  },
  {
    id: 15,
    productImage: "/Product Details Page/MH Home Automation Kit.jpg",
    title: "MH Home Automation Kit",
    description: "",
    buttonText: "Launch Your Learning Journey",
    // details: "The Humanoid Kit, where creativity meets technology! This hands-on kit allows kids to build and program a humanoid robot that can walk and dance, introducing them to the exciting world of robotics, coding and AI.",
    details: "The MH Home Automation Kit is a fantastic way for kids to learn about electronics and smart home technology. Using laser-cut wood boards, sensors, actuators, and a control board, this kit lets you build your own model smart house. Through hands-on assembly and exploration, you’ll gain a solid understanding of how modern smart homes work.",
    outcomeTitle: "Outcome",
    // outcomeDescription: "Develop critical thinking and troubleshooting abilities through hands-on robot assembly and coding challenges.",
    outcomeDescription: "With the MH Home Automation Kit, you’ll learn how to integrate sensors for lighting, motion detection, and even soil moisture monitoring. You’ll also discover how to set up a gas leak alert system, control everything with a remote app, and explore the basics of coding and automation. This kit is perfect for learning about electronics, Arduino programming, and how to turn smart home ideas into practical applications.",
    features: [
      {
        title: "Quality",
        description: "Each kit includes durable and reliable parts designed to withstand repeated use and experimentation.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Innovation",
        description: "Provides opportunities to explore artificial intelligence concepts, enabling robots to learn and adapt to new tasks.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    // conclusionText: "Inspire innovation and creativity with the Humanoid Kit, preparing young minds to become pioneers in technology and robotics.",
    conclusionText: "Turn your home into a smart home with the MH Home Automation Kit! This kit teaches you how to control lights, fans, and other devices using sensors and a smartphone. You’ll learn the basics of home automation and how smart technology can make life easier. Whether it’s turning off the lights with a voice command or setting up automatic timers, this kit is perfect for students who love gadgets and want to explore how technology can transform everyday tasks. Step into the future of home living with this fun and educational  project!",
  },
  {
    id: 16,
    productImage: "/Product Details Page/Self-Balancing Car Kit.jpg",
    title: "Self-Balancing Car Kit",
    description: "",
    buttonText: "Launch Your Learning Journey",
    // details: "The Humanoid Kit, where creativity meets technology! This hands-on kit allows kids to build and program a humanoid robot that can walk and dance, introducing them to the exciting world of robotics, coding and AI.",
    details: "The Self-Balancing Car Kit is an exciting and educational project for kids to explore robotics and engineering. This kit allows you to build a car that can balance itself on two wheels, using sensors and motors to stay upright. It’s a great way to dive into concepts like motion control, stability, and automation while having fun building your own robot car.",
    outcomeTitle: "Outcome",
    // outcomeDescription: "Develop critical thinking and troubleshooting abilities through hands-on robot assembly and coding challenges.",
    outcomeDescription: "By building the Self-Balancing Car, you’ll learn how sensors and motors work together to maintain balance and control motion. You’ll explore concepts like gyroscopic stability, feedback loops, and programming, all while developing problem-solving skills and a deeper understanding of robotics. This project enhances creativity and introduces the basics of automation and mechanical design in an engaging, hands-on way.",
    features: [
      {
        title: "Quality",
        description: "Each kit includes durable and reliable parts designed to withstand repeated use and experimentation.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Innovation",
        description: "Provides opportunities to explore artificial intelligence concepts, enabling robots to learn and adapt to new tasks.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    // conclusionText: "Inspire innovation and creativity with the Humanoid Kit, preparing young minds to become pioneers in technology and robotics.",
    conclusionText: "Build and program your own car that balances on two wheels! Learn how sensors and coding work together to create real-world tech. Perfect for young engineers ready to explore robotics and control systems!",
  },
  {
    id: 17,
    productImage: "/Product Details Page/Solar Tracking Kit.jpg",
    title: "Solar Tracking Kit",
    description: "",
    buttonText: "Launch Your Learning Journey",
    // details: "The Humanoid Kit, where creativity meets technology! This hands-on kit allows kids to build and program a humanoid robot that can walk and dance, introducing them to the exciting world of robotics, coding and AI.",
    details: "The Solar Tracking Kit for Arduino is an engaging and educational tool designed to help users explore solar energy and electronics. This kit includes components like ambient light sensors, servos, a solar panel, and various other modules that allow you to build a device capable of tracking the sun and converting light into electricity. With added features such as a smartphone charging module, temperature and humidity sensors, a light sensor, and an LCD display, it offers a hands-on experience for teens, adults, and professionals. It is easy to assemble and program, making it ideal for learning about renewable energy, electronics, and programming.",
    outcomeTitle: "Outcome",
    // outcomeDescription: "Develop critical thinking and troubleshooting abilities through hands-on robot assembly and coding challenges.",
    outcomeDescription: "Using the Solar Tracking Kit, learners will develop a strong understanding of how solar energy can be effectively captured through a tracking system. They will gain practical experience with Arduino programming, controlling sensors and servos, and working with electronics. The kit encourages problem-solving, creative thinking, and hands-on experimentation, while teaching important concepts in energy efficiency, automation, and basic mechanics. The extendable design also allows users to connect additional sensors and modules, promoting further exploration and customization.",
    features: [
      {
        title: "Quality",
        description: "Each kit includes durable and reliable parts designed to withstand repeated use and experimentation.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Innovation",
        description: "Provides opportunities to explore artificial intelligence concepts, enabling robots to learn and adapt to new tasks.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    // conclusionText: "Inspire innovation and creativity with the Humanoid Kit, preparing young minds to become pioneers in technology and robotics.",
    conclusionText: "Harness the power of the sun with this Solar Tracking Kit! Learn how to build a system that follows the sun’s movement, maximizing energy efficiency. Perfect for students eager to explore renewable energy and innovative engineering.",
  },
  {
    id: 18,
    productImage: "/Product Details Page/Bluetooth Control Car.jpg",
    title: "Bluetooth Control Car",
    description: "",
    buttonText: "Launch Your Learning Journey",
    // details: "The Humanoid Kit, where creativity meets technology! This hands-on kit allows kids to build and program a humanoid robot that can walk and dance, introducing them to the exciting world of robotics, coding and AI.",
    details: "The Bluetooth Control Car is a fun and educational kit designed for kids to explore basic electronics and wireless control. Using a simple Bluetooth connection, kids can control their car's movement through a smartphone or tablet, making it a hands-on way to learn about remote control systems. The kit is easy to assemble and offers an exciting introduction to programming and robotics.",
    outcomeTitle: "Outcome",
    // outcomeDescription: "Develop critical thinking and troubleshooting abilities through hands-on robot assembly and coding challenges.",
    outcomeDescription: "With the Bluetooth Control Car, kids will gain an understanding of wireless communication, motor control, and basic electronics. They will learn how to control the car's movement through a Bluetooth-enabled device, enhancing problem-solving skills and creativity. This project provides a fun and interactive way for children to explore STEM concepts while building their own remote-controlled car.",
    features: [
      {
        title: "Quality",
        description: "Each kit includes durable and reliable parts designed to withstand repeated use and experimentation.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Innovation",
        description: "Provides opportunities to explore artificial intelligence concepts, enabling robots to learn and adapt to new tasks.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    // conclusionText: "Inspire innovation and creativity with the Humanoid Kit, preparing young minds to become pioneers in technology and robotics.",
    conclusionText: "Take control of your own car using just your phone! With the Bluetooth Control Car kit, you’ll learn how to program and drive a car remotely. It's a fun and interactive way to explore robotics and wireless technology!",
  },

];

export default products;
